<template>
  <!-- title="滑块验证码" -->
  <el-dialog v-model="dialogVisible" :close-on-click-modal="false" width="350">
    <div class="flex">
      <img :src="imgUrl" alt="" @click="refresh" style="cursor: pointer;">
      <div style="margin: 0 10px;">=</div>
      <el-input v-model="result" style="width: 50px;"></el-input>
      <!-- <Vcode :show="dialogVisible" @success="onSuccess" type="inside" @close="onClose" /> -->
      <!-- <span>{{ msg }}</span> -->
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="confirm">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import {
  getCaptcha
} from "@/api/ai";
import Vcode from "vue3-puzzle-vcode";
import { v4 as uuidv4 } from "uuid";
import { ElMessage } from "element-plus";


const dialogVisible = ref(false)
const emit = defineEmits(['success', 'close'])

const result = ref('')

const refresh = async () => {
  captchaKey.value = uuidv4()
  const { data: res } = await getCaptcha({ key: captchaKey.value })
  imgUrl.value = res.data
}


// const onSuccess = () => {
//   console.log('成功');
//   emit('success')
//   dialogVisible.value = false
// }

// const onClose = () => {
//   console.log('关闭');
// }
const captchaKey = ref('')
const imgUrl = ref('')

const open = async () => {
  dialogVisible.value = true
  captchaKey.value = uuidv4()
  const { data: res } = await getCaptcha({ key: captchaKey.value })
  imgUrl.value = res.data
}
const close = () => {
  dialogVisible.value = false
  captchaKey.value = ''
  result.value = ''
  imgUrl.value = ''
}

const confirm = () => {
  if (result.value === '') {
    ElMessage.error('请输入验证码')
    return
  }
  emit('success', { key: captchaKey.value, res: result.value })
  close()
}

defineExpose({
  open,
  close
})

</script>

<style lang="scss" scoped>
.flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-dialog) {
  width: 500px;
  border-radius: 16px;
  margin: auto;
}

:deep(.el-dialog__header) {
  display: none;
}

:deep(.slide-verify-slider) {
  border-radius: 33px;
}

:deep(.slide-verify-slider-mask) {
  border-radius: 33px 0 0 33px;
}
</style>